<template>
<!-- Ce composant est utilisé dans la vue des récoltes et celle des rendements -->
  <div>
    <!-- Date et heure de récolte -->
    <div class="row-resize">
      <b-row v-if="!onlyDate">
        <!-- Intitulé de la date-->
        <b-col class="text-infos shape-first-cell whitout-bg"><img :src="iconCalendar" alt="calendar" />
              <span class="text-activity-notes subtitle-params">{{ $t("harvestStorage.list.date") }}</span></b-col>
        <!-- Intitulé de l'heure-->
        <b-col class="text-infos shape-second-cell whitout-bg"><img :src="iconClock" alt="clock" />
              <span class="text-activity-notes subtitle-params">{{ $t("harvestStorage.edit.hour") }}</span></b-col>
      </b-row>

      <b-row>
        <!-- Date Input-->
        <b-col class="text-infos shape-first-cell whitout-bg">
          <div class="inputs-params">
            <input type="date" ref="harvestDate" id="harvestDate" v-model="harvestDate" :required="!isOnYield" placeholder="yyyy-mm-dd"/>
          </div>
        </b-col>
        <!-- hour input -->
        <b-col v-if="!onlyDate" class="text-infos shape-second-cell whitout-bg">
          <div class="inputs-params">
            <b-input type="time" ref="harvestTime" v-model="harvestTime" size="sm" placeholder="hh:mm" />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import clock from "@/assets/images/clock.svg";
import calendar from "@/assets/images/calendar.svg";
import { mapGetters } from "vuex";
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import LoadFormPartMixin from "@/mixins/LoadFormPartMixin";
import {checkDatePattern, checkTimePattern, formatDate} from "@/utils/commons";

export default {
  mixins: [DetailUpdateMixin, LoadFormPartMixin],
  props: {
    onlyDate: {
      // si True, alors le champ de date ne contient pas d'information sur l'heure
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      iconClock: clock,
      iconCalendar: calendar,
      harvestDate: "",
      harvestTime: "",
      loadComponent: false,
      isOnYield: false,
    };
  },
  mounted() {
    this.loadComponent = false;
    this.isOnYield = this.$route.name.includes("yield");
    if (this.$route.name === "harvestStorage.edit" && this.currentEntry.doneDate) {
      this.harvestDate = this.currentEntry.doneDate.slice(0, 10);
      this.harvestTime = formatDate(this.currentEntry.doneDate, "time");
    }
    if (this.$route.name === "yield.edit" && this.currentEntry.harvestDate) {
      this.harvestDate = this.currentEntry.harvestDate;
    }
    this.loadComponent = true;
  },
  methods: {


    /**
     * Description: Retourne la date de récolte pour le panneau des récoltes.
     */
    getDoneDate() {

      if (this.harvestTime === "") this.harvestTime = "08:00";

      let TimeArray = this.harvestTime.split(':')
      let hours = TimeArray[0] != undefined ? TimeArray[0] : 0;
      let minutes = TimeArray[1] != undefined ? TimeArray[1] : 0;

      var date = new Date(this.harvestDate);
      date.setHours(hours, minutes);

      if (date != "Invalid Date") return date;

      return null;
    },

    /**
     * Description: Retourne la date de récolte pour le panneau des rendements.
     */
    getHarvestDate() {

      if (this.harvestDate != "") return this.harvestDate;

      return null;
    },
    /**
     * Description: Confirme le chargement des données de ce composant.
     * @return {Boolean} Component loaded.
     */
    validateForm: function() {
      this.isValid = true;
      return this.loadComponent;
    },

  },
  
  computed: {
    ...mapGetters({ currentHarvest: "harvestStorage/currentEntry" }),
  },
  watch: {
    harvestDate: {
      handler: async function() {
        if(!checkDatePattern(this.harvestDate)) {
          this.$refs.harvestDate.setCustomValidity(this.$i18n.t("commons.details.datePatternError"));
          return
        }
        this.$refs.harvestDate.setCustomValidity("");
        if (this.isOnYield)
          await this.$store.dispatch(this.storeEditAction, {harvestDate: this.getHarvestDate()});
        else
          await this.$store.dispatch(this.storeEditAction, {doneDate: this.getDoneDate(),});
      }
    },
    harvestTime: {
      handler: async function() {
        if(!checkTimePattern(this.harvestTime)) {
          this.$refs.harvestTime.setCustomValidity(this.$i18n.t("commons.details.timePatternError"))
        }
        else {
          this.$refs.harvestTime.setCustomValidity("");
          await this.$store.dispatch(this.storeEditAction, {doneDate: this.getDoneDate(),});
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>

/**
 * Inputs params.
 */
.inputs-params {
  margin: 2px 10px 3px 0px;
  // width: 47%;
}

/**
 * Subtitle params.
 */
.subtitle-params {
  margin: auto 0px 2px 0px;
}

img {
  width: 1.5em;
  float: left;
  margin: auto 5px 5px auto;
}

/**
 * Resize rows.
 */
.row-resize{
    padding-left: 16px;
}
</style>
